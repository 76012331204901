import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authActionlogout } from '../../../context/redux/actions/authAction';
import { memoActionDelAll } from '../../../context/redux/actions/memoAction';
import { dataTaggerActionClearAll } from '../../../context/redux/actions/dataTaggerAction';
import { sequenceActionReset } from '../../../context/redux/actions/sequenceAction';
import { ServerRequest } from '../../../utils/apiweb';
import { REQUEST_METHOD } from "../../../consts/requestMethodType";
import { APIS } from '../../../config/apis';

import logo from '../../../assets/images/LogoMunicipio.png';
import { HeaderContext } from '../context'

import './index.css';
import { UserSubMenu } from '../Header/components';


function HeaderBasic() {

    const dispatch = useDispatch();

    const { isAuthenticated } = useSelector(state => state.auth)

    const handleClickLogout = () => {
        const callbackSuccess = () => {
            dispatch( memoActionDelAll() );
            dispatch( dataTaggerActionClearAll() );
            dispatch( sequenceActionReset() );
            dispatch( authActionlogout() );
        };

        const path = '/expirate';

        ServerRequest(
            REQUEST_METHOD.PUT,
            null,
            true,
            APIS.URLS.SESION,
            path,
            null,
            callbackSuccess,
            callbackSuccess,
            callbackSuccess
        );
    }

    return (

        <nav className="navbar navbar-expand-sm p-top-15">

            {/*  <img src={logo} alt="logo" className='navlogo link'/> */}
            <div className='navlogo m-left-17 logo' alt="logo"></div>

            <div className="collapse navbar-collapse">

            </div>

            <div className='collapse navbar-collapse login-nav'>
                <HeaderContext.Provider value={{ isAuthenticated, handleClickLogout }}>
                    <UserSubMenu />
                </HeaderContext.Provider>
            </div>
    
        </nav>

    )
}

export default HeaderBasic;