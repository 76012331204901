import { CONTEXT_ACTION_TYPE as type } from '../../../consts/contextActionType';

export const sequenceActionReset = () => ({
    type: type.SEQUENCE_RESET,
    payload: {

    }
});

export const sequenceActionNext = () => ({
    type: type.SEQUENCE_NEXT,
    payload: {

    }
});
