import React, { useState, useEffect, useMemo } from 'react';

import { REQUEST_METHOD } from "../../consts/requestMethodType";
import { APIS } from '../../config/apis';
import { useForm } from '../../components/hooks/useForm';
import { ServerRequest } from '../../utils/apiweb';
import { ALERT_TYPE } from '../../consts/alertType';
import { APPCONFIG } from '../../app.config';
import { MESSAGE_MODALS } from '../../context/custom/MessageModalContext';
import { useManagedContext } from '../../components/hooks';
import { Loading, TableCustom, SectionHeading, AdvancedSearch, InputWithEnterEvent } from '../../components/common';
import DataTaggerModalApi from '../../components/controls/DataTaggerModalApi';
import ShowToastMessage from '../../utils/toast';
import UsuarioModal from '../../components/controls/UsuarioModal';
import { useAccess } from '../../components/hooks/useAccess';
import { useLocation } from 'react-router-dom';


function UsuariosView() {
    const { showMessageModal } = useManagedContext()
    const location = useLocation()

    //hooks
    const [state, setState] = useState({
        loading: false,
        showForm: false,
        modeFormEdit: false,
        rowId: 0,
        list: []
    });
    const [dataTaggerId, setDataTaggerId] = useState(null)

    const [filters, setFilters] = useState({
        labels: [
            { title: 'Código', field: 'codigo', value: '', valueIgnore: ''},
            { title: 'Nombre Apellido', field: 'nombreApellido', value: '', valueIgnore: '' },
            { title: 'Email', field: 'email', value: '', valueIgnore: '' }
        ]
    });

    const [ formValues, formHandle, , formSet ] = useForm({
        codigo: '',
        nombreApellido: '',
        email: ''
    });

    const filteredData = useMemo(() => (
        state.list.filter(x => (
            (formValues.codigo.value === '' || x.codigo.toLowerCase().includes(formValues.codigo.toLowerCase()))
            && (formValues.nombreApellido.value === '' || x.nombreApellido.toLowerCase().includes(formValues.nombreApellido.toLowerCase()))
            && (formValues.email.value === '' || x.email.toLowerCase().includes(formValues.email.toLowerCase()))
        ))
    ), [filters, state.list])

    const [usuarios, setUsuarios] = useState([]);

    const [hasEditAccess, setHasEditAccess] = useState(false);
    useAccess({
        key: 'UsuariosView',
        onLoaded: (data, isSuccess, error) => {
            if (!isSuccess) ShowToastMessage(ALERT_TYPE.ALERT_ERROR, error)
            else setHasEditAccess(data.some(x => x.codigo === 'usuario_edit'))
        }
    });

    const mount = () => {

        SearchUsuarios();

        const unmount = () => {}
        return unmount;
    }
    useEffect(mount, []);

    //definiciones
    const cellA = (props) => !hasEditAccess ? '' : (
        <div className='action'>
            <div onClick={ (event) => handleClickUsuarioAdd() } className="link">
                <span className="material-symbols-outlined" title="Nuevo">add</span>
            </div>
        </div>
    )

    const cellVMRB = (props) => (
        <div className='action'>
            <div onClick={ (event) => handleClickUsuarioView(props.value) } className="link">
                <span className="material-symbols-outlined" title="Ver">search</span>
            </div>
            {hasEditAccess && <>
                <div onClick={ (event) => handleClickUsuarioModify(props.value) } className="link">
                    <span className="material-symbols-outlined" title="Modificar">stylus</span>
                </div>
                <div onClick={ (event) => handleClickUsuarioRemove(props.value) } className="link">
                    <span className="material-symbols-outlined" title="Eliminar">delete</span>
                </div>
                <div onClick={ (event) => handleClickUsuarioBind(props.value) } className="link">
                    <span className="material-symbols-outlined" title="Asignar perfiles">link</span>
                </div>
            </>}
            <div onClick={() => handleClickUsuarioInfo(props.value)} className="link">
                <span className="material-symbols-outlined" title="Información adicional">info</span>
            </div>
        </div>
    )

    const tableColumns = [
        { Header: 'Usuario', accessor: 'codigo'},
        { Header: 'Nombre y Apellido', accessor: 'nombreApellido'},
        { Header: 'E-Mail', accessor: 'email', disableSortBy: true },
        { Header: cellA, Cell: cellVMRB, accessor: 'id', width: '180px', disableGlobalFilter: true, disableSortBy: true }
    ];

    //handles
    const handleClickUsuarioAdd = () => {
        setState(prevState => {
            return {...prevState, showForm: true, modeFormEdit: true, rowId: 0};
        });
        setUsuarios(state.list.map(x => x.codigo.toLowerCase()));
    }
    const handleClickUsuarioView = (id) => {
        setState(prevState => {
            return {...prevState, showForm: true, modeFormEdit: false, rowId: parseInt(id)};
        });
    }
    const handleClickUsuarioModify = (id) => {
        setState(prevState => {
            return {...prevState, showForm: true, modeFormEdit: true, rowId: parseInt(id)};
        });
        setUsuarios(state.list.filter(x => x.id !== id).map(x => x.codigo.toLowerCase()));
    }
    const handleClickUsuarioRemove = (id) => {
        const usuario = state.list.find(x => x.id === id)
        showMessageModal({
            ...MESSAGE_MODALS.BORRAR,
            message: `¿Está seguro de borrar el usuario ${usuario.nombreApellido}?`,
            onConfirm: () => RemoveUsuario(id),
        })
    }
    const handleClickUsuarioBind = (id) => {
        const url = APPCONFIG.SITE.WEBAPP + 'usuario/perfiles/' + id;
        window.location.href = url;
    }

    const handleClickUsuarioInfo = (id) => {
        setDataTaggerId(id)
    }

    //callbacks
    const callbackNoSuccess = (response) => {
        response.json()
        .then((error) => {
          const message = error.message;
          ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
          setState(prevState => {
            return {...prevState, loading: false};
          });
        })
        .catch((error) => {
            const message = 'Error procesando respuesta: ' + error;
            ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
            setState(prevState => {
              return {...prevState, loading: false};
            });
        });
    }
    const callbackError = (error) => {
        const message = 'Error procesando solicitud: ' + error.message;
        ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
        setState(prevState => {
          return {...prevState, loading: false};
        });
    }

    //funciones
    function SearchUsuarios() {

        setState(prevState => {
            return {...prevState, loading: true, list: []};
        });

        const callbackSuccess = (response) => {
            response.json()
            .then((data) => {
                setState(prevState => {
                    return {...prevState, loading: false, list: data};
                });
            })
            .catch((error) => {
                const message = 'Error procesando respuesta: ' + error;
                ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
                setState(prevState => {
                    return {...prevState, loading: false};
                });
            });
        };

        ServerRequest(
            REQUEST_METHOD.GET,
            null,
            true,
            APIS.URLS.USUARIO,
            null,
            null,
            callbackSuccess,
            callbackNoSuccess,
            callbackError
        );

    }

    function RemoveUsuario(id) {

        setState(prevState => {
            return {...prevState, loading: true};
        });

        const callbackSuccess = (response) => {
            response.json()
            .then((id) => {
                setState(prevState => {
                    return {...prevState, loading: false};
                });
                SearchUsuarios();
            })
            .catch((error) => {
                const message = 'Error procesando respuesta: ' + error;
                ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
                setState(prevState => {
                    return {...prevState, loading: false};
                });
            });
        };

        const paramsUrl = `/${id}`;

        ServerRequest(
            REQUEST_METHOD.DELETE,
            null,
            true,
            APIS.URLS.USUARIO,
            paramsUrl,
            null,
            callbackSuccess,
            callbackNoSuccess,
            callbackError
        );

    }

    function ApplyFilters() {
        UpdateFilters();
        SearchUsuarios();
    }

    function UpdateFilters() {
        let labels = [...filters.labels];
        labels.forEach((label) => {
            label.value = formValues[label.field];
        });
        setFilters(prevState => {
            return {...prevState, labels: labels};
        });
    }


    return (
    <>

        <Loading visible={state.loading}></Loading>

        {state.showForm && 
            <UsuarioModal
                id={state.rowId}
                disabled={!state.modeFormEdit}
                data={{
                    listUsuarios: usuarios
                }}
                onDismiss={() => {
                    setState(prevState => {
                        return {...prevState, showForm: false, rowId: 0};
                    });
                }}
                onConfirm={(id) => {
                    setState(prevState => {
                        return {...prevState, showForm: false, rowId: 0};
                    });
                    SearchUsuarios();
                }}
            />
        }

        <SectionHeading titles={['Administración de Usuarios/as']} />

        <section className='section-accordion'>

            <AdvancedSearch
                formValues={formValues}
                formSet={formSet}
                labels={filters.labels.filter(f => f.value !== f.valueIgnore)}
                onSearch={ApplyFilters}
            >

                <div className='row form-basic'>
                    <div className="col-12 col-md-4">
                        <label htmlFor="codigo" className="form-label">Código</label>
                        <InputWithEnterEvent
                            name="codigo"
                            type="text"
                            placeholder=""
                            className="form-control"
                            value={formValues.codigo}
                            onChange={ formHandle }
                            onEnter={ApplyFilters}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <label htmlFor="nombre" className="form-label">Nombre</label>
                        <InputWithEnterEvent
                            name="nombreApellido"
                            type="text"
                            placeholder=""
                            className="form-control"
                            value={formValues.nombreApellido}
                            onChange={ formHandle }
                            onEnter={ApplyFilters}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <label htmlFor="email" className="form-label">Email</label>
                        <InputWithEnterEvent
                            name="email"
                            type="text"
                            placeholder=""
                            className="form-control"
                            value={formValues.email}
                            onChange={ formHandle }
                            onEnter={ApplyFilters}
                        />
                    </div>
                </div>
            </AdvancedSearch>

            <div className="m-top-20">

                <TableCustom
                    className={'TableCustomBase'}
                    columns={tableColumns}
                    data={filteredData}
                />

            </div>

        </section>
        
        {dataTaggerId &&
            <DataTaggerModalApi
                title="Información adicional de Usuario"
                entidad="Usuario"
                idEntidad={dataTaggerId}
                disabled={!hasEditAccess}
                onConfirm={() => setDataTaggerId(null)}
                onDismiss={() => setDataTaggerId(null)}
            />
        }
    </>
    )
}

export default UsuariosView;
