import { useState, useMemo } from 'react'
import { NavLink } from 'react-router-dom';

import UserSubMenu from '../UserSubMenu';

// import logo from '../../../../../assets/images/LogoMunicipio.png';
import './index.scss'

const NavBarCompact = ({ isAuthenticated, publicRoutes, privateRoutes, handleClickHome, handleClickMenu, handleClickLogout }) => {
    const [menuOpen, setMenuOpen] = useState(false)

    const onClickFinal = (index) => {
        setMenuOpen(false)
        handleClickMenu(index)
    }

    const topRoutes = useMemo(
        () => (isAuthenticated ? privateRoutes : publicRoutes).filter(route => route.nivelMenu === 1),
        [isAuthenticated, publicRoutes, privateRoutes]
    )

    return (
        <div className="navbar-compact">
            <div className="navbar-row">
                <div onClick={handleClickHome}>
                     {/*  <img src={logo} alt="logo" className='navlogo link'/> */}
                <div className='navlogo logo' alt="logo"></div>
                </div>
        
                <div className="display-row" style={{ flex: 1 }}>
                    <a className="nav-link" href="#">
                        <div className="display-row" onClick={() => setMenuOpen(x => !x)}>
                            <span className="material-symbols-outlined">menu</span>
                            <div className="nav-item p-left-10">Menú</div>
                        </div>
                    </a>
                </div>

                <UserSubMenu />
            </div>

            <div className={"navbar-compact-menu"} style={{ maxHeight: menuOpen ? topRoutes.length * 35 + 15 : 0 }}>
                {topRoutes.map((route, index) => (
                    (route.children.length === 0) ? (
                        <div className="" key={index} onClick={() => onClickFinal(null)}>
                            {Array.isArray(route.path) ?
                            <NavLink to={route.path[0]} className="nav-link">{route.title}</NavLink> :
                            <NavLink to={route.path} className="nav-link">{route.title}</NavLink>}
                        </div>
                    ) : (
                        <div className="dropdown" key={index}>
                            <a className="nav-link" href="#" onClick={() => handleClickMenu(route.index)}>
                                {route.title}
                            </a>
                            <ul className={(route.selected) ? "dropdown-menu show" : "dropdown-menu"} style={{ position: 'fixed' }}>
                                {privateRoutes.filter(subitem => route.children.includes(subitem.index) && subitem.nivelMenu === 2).map((subroute, subindex) =>
                                    <li key={subindex} onClick={() => onClickFinal(null)}>
                                        {Array.isArray(subroute.path) ?
                                        <NavLink to={subroute.path[0]} className="nav-link">{subroute.title}</NavLink> :
                                        <NavLink to={subroute.path} className="nav-link">{subroute.title}</NavLink>}
                                    </li>
                                )}
                            </ul>
                        </div>
                    )
                ))}
            </div>
        </div>
    )
}

export default NavBarCompact
