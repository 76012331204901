
export const CONTEXT_ACTION_TYPE = {

    AUTH_LOGIN: 'AUTH_LOGIN',
    AUTH_LOGOUT: 'AUTH_LOGOUT',

    MEMO_SET: 'MEMO_SET',
    MEMO_DEL: 'MEMO_DEL',
    MEMO_DEL_ALL: 'MEMO_DEL_ALL',

    DATA_TAGGER_CLEAR: 'DATA_TAGGER_CLEAR',
    DATA_TAGGER_CLEAR_ALL: 'DATA_TAGGER_CLEAR_ALL',
    DATA_TAGGER_SET: 'DATA_TAGGER_SET',
    DATA_TAGGER_ADD: 'DATA_TAGGER_ADD',
    DATA_TAGGER_MODIFY: 'DATA_TAGGER_MODIFY',
    DATA_TAGGER_REMOVE: 'DATA_TAGGER_REMOVE',

    SEQUENCE_RESET: 'SEQUENCE_RESET',
    SEQUENCE_NEXT: 'SEQUENCE_NEXT',

}