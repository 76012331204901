import React from 'react';
import { useDispatch } from 'react-redux';
import { APIS } from '../../config/apis';
import { ALERT_TYPE } from '../../consts/alertType';
import { authActionLogin } from '../../context/redux/actions/authAction';
import { useForm } from '../../components/hooks/useForm';
import ShowToastMessage from '../../utils/toast';
import { APPCONFIG } from '../../app.config';
import encrypt from '../../utils/encrypt';
import HeaderBasic from '../../components/layout/HeaderBasic';
import { onRequestError, standardRequest } from '../../utils/requests';
import { useManagedContext } from '../../components/hooks';
import { InputSecret } from '../../components/common';


function LoginView() {

    const { setIsLoading } = useManagedContext()

    const [ formValues, formHandle ] = useForm({
        username: '',
        password: ''
    });

    const dispatch = useDispatch();

    const handleClick = () => {
        if (isFormValid()) {
            Login();
        }
    }

    const isFormValid = () => {
        if (formValues.username.length === 0) {
            ShowToastMessage(ALERT_TYPE.ALERT_WARNING, 'Usuario incorrecto');
            return false;
        }
        else if (formValues.password.length === 0) {
            ShowToastMessage(ALERT_TYPE.ALERT_WARNING, 'Password incorrecto');
            return false;
        }
        else {
            return true;
        }
    }

    const onClickRecoverPassword = () => {
        if (formValues.username === '') {
            ShowToastMessage(ALERT_TYPE.ALERT_WARNING, 'Debe ingresar su usuario')
            return
        }

        setIsLoading(true)

        standardRequest('GET', `${APPCONFIG.SITE.WEBAPI}api/usuario/recovery-token/${formValues.username}`)
        .catch(onRequestError)
        .then(() => ShowToastMessage(ALERT_TYPE.ALERT_SUCCESS, 'Revise su correo para recuperar la contraseña'))
        .finally(() => setIsLoading(false))
    }

    const Login = () => {
        setIsLoading(true)

        const body = {
            username: formValues.username,
            password: encrypt(formValues.password),
        }

        standardRequest('POST', APIS.URLS.LOGIN, body, { format: 'text' })
        .then(token => dispatch(authActionLogin(formValues.username, token)))
        .catch(e => {
            if (e.message) ShowToastMessage(ALERT_TYPE.ALERT_ERROR, e.message)
            else ShowToastMessage(ALERT_TYPE.ALERT_ERROR, 'Error procesando solicitud')
        })
        .finally(() => setIsLoading(false))
    };

    return (
    <div>
        <HeaderBasic showMenu={false} />
        <div className='bg-img'>
                
            <div className="login-container">
                <section className='lg-c'>

                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">Usuario/a</label>
                        <input 
                            name="username"
                            type="text"
                            placeholder=""
                            className="form-control"
                            value={ formValues.username }
                            onChange={ formHandle }
                        />
                    </div>
                    <div className="mb-3">
                        <InputSecret
                            title="Contraseña"
                            name="password"
                            value={formValues.password}
                            onChange={formHandle}
                            onPressEnter={handleClick}
                            autoComplete="off"
                        />
                    </div>
                    <a href='#' className="login-link" onClick={onClickRecoverPassword}>¿Olvidó su contraseña?</a>
                    <div className="mb-3 lg-btn">
                        <button className="btn btn-main" onClick={ handleClick }>Ingresar</button>
                    </div>

                </section>
            </div>

        </div>
    </div>
    )
}

export default LoginView;
