import React from 'react';
import { useSelector } from 'react-redux';

import LocalStorage from '../../context/storage/localStorage';
import { APPCONFIG } from '../../app.config';
import HeaderBasic from '../../components/layout/HeaderBasic';

import './index.scss';


function WelcomeView() {

    const token = LocalStorage.get("accessToken");
    const {username} = useSelector( (state) => state.auth );

    const handleClickAdministracion = () => {
        const url = APPCONFIG.WEBAPP.ADMINISTRACION + `?username=${username}&token=${token}`;
        window.location.href = url;
    }

    const handleClickIngresosPublicos = () => {
        const url = APPCONFIG.WEBAPP.INGRESOS_PUBLICOS + `?username=${username}&token=${token}`;
        window.location.href = url;
    }

    const handleClickSeguridad = () => {
        const url = APPCONFIG.WEBAPP.SEGURIDAD + `?username=${username}&token=${token}`;
        window.location.href = url;
    }
    
    const handleClickTesoreria = () => {
        const url = APPCONFIG.WEBAPP.TESORERIA + `?username=${username}&token=${token}`;
        window.location.href = url;
    }

    return (
    <>  
        <HeaderBasic showMenu={false} />

        <div>
            <div className='ingreso-container'>
                <section className='ingreso-menu'>

                    <div className='row'>
                        <div className="col-12 col-md-6 col-xl-3 ingreso">
                            <div onClick={ handleClickSeguridad } className="w-app nav-link">
                             {/*    <img src={configuracion} alt="seguridad" className='ingreso-icon seguridad'/>*/}
                                <div className='ingreso-icon conf-icon' alt="configuracion"></div>
                                <span className='ingreso-text'>CONFIGURACIÓN</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3 ingreso">
                            <div onClick={ handleClickAdministracion } className="nav-link">
                               {/*  <img src={administracion} alt="administracion" className='ingreso-icon adm-icon'/>*/}
                                <div className='ingreso-icon adm-icon' alt="administracion"></div>
                                <span className='ingreso-text'>ADMINISTRACIÓN</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3 ingreso">
                            <div onClick={ handleClickIngresosPublicos } className="nav-link">
                               {/*  <img src={ing_publicos} alt="ingresos-publicos" className='ingreso-icon'/>*/}
                                <div className='ingreso-icon ing-icon' alt="ingresos-publicos"></div>
                                <span className='ingreso-text'>INGRESOS PÚBLICOS</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3 ingreso">
                            <div onClick={ handleClickTesoreria } className="nav-link">
                              {/*   <img src={tesoreria} alt="tesoreria" className='ingreso-icon'/>*/}
                                <div className='ingreso-icon tes-icon' alt="tesoreria"></div>
                                <span className='ingreso-text'>TESORERÍA</span>
                            </div>
                        </div>
                    </div>

                </section>   
                {/* <div className="login-container">
                    <section className='ingreso_container'>
                    </section>
                </div> */}
            </div>
        </div>
    </>
    )
    
}

export default WelcomeView;