import { APPCONFIG } from '../app.config';

export const APIS = {
        URLS: {
        LISTA: APPCONFIG.SITE.WEBAPI + 'api/lista',
        USUARIO: APPCONFIG.SITE.WEBAPI + 'api/usuario',
        PERFIL: APPCONFIG.SITE.WEBAPI + 'api/perfil',
        PERMISO: APPCONFIG.SITE.WEBAPI + 'api/permiso',
        LOGIN: APPCONFIG.SITE.WEBAPI + 'api/login',
        SESION: APPCONFIG.SITE.WEBAPI + 'api/sesion',
        ACCESO: APPCONFIG.SITE.WEBAPI + 'api/acceso',
        INFORMACION_ADICIONAL: APPCONFIG.SITE.WEBAPI + 'api/informacion-adicional',
        FILE: APPCONFIG.SITE.WEBAPI + 'api/file',
        ARCHIVO: APPCONFIG.SITE.WEBAPI + 'api/archivo',
        OBSERVACION: APPCONFIG.SITE.WEBAPI + 'api/observacion',
        ETIQUETA: APPCONFIG.SITE.WEBAPI + 'api/etiqueta',
        ENTIDAD: APPCONFIG.SITE.WEBAPI + 'api/entidad',
    }
}
